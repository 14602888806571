import React from "react";
import { Link } from "gatsby";

import { Layout, CaseStudyHeader, ContactFormLight } from "../components";
import locales from "../constants";
// import ReactPlayer from "react-player";

const pageCaseStudy = ({ pageContext }) => {
	const { title, caseContent, seo } = pageContext.data;
	return (
		<Layout
			seo={{
				...seo,
				href: "pl",
				lang: "pl",
			}}
		>
			<CaseStudyHeader title={title} />
			<div className="trader-wrapper--case-studies">
				<div className="trader-widget">
					<div className="trader-widget__avatar">
						<img
							src={require("../assets/images/aleksandra-szymanska-when.png")}
							alt=""
						/>
						{/* <ReactPlayer
							url="https://when.zenx.pl/wp-content/themes/adream/assets/video/persons/karolina-kopera.mp4"
							width="100%"
							height="100%"
							playing={true}
							muted={true}
							playsinline={true}
							loop={true}
						/> */}
					</div>
					<p className="trader-widget__name">Aleksandra Szymańska</p>
					<ul className="trader-widget__list">
						<li>
							Customer Success
							<br />
							Team Leader
						</li>
						<li>
							<a href="tel:+48790218698">+48 790 218 698</a>
						</li>
						<li>
							<a href="mailto:a.szymanska@when.pl">
								a.szymanska@when.pl
							</a>
						</li>
						<Link
							to="/kontakt/"
							className="trader-widget__button btn btn-box"
						>
							skontaktuj się
						</Link>
					</ul>
				</div>
			</div>
			<section className="case-single-content">
				{caseContent.map((item) => (
					<div>
						{item.content ? (
							<div
								className="case-container"
								dangerouslySetInnerHTML={{
									__html: item.content,
								}}
							/>
						) : item.full_img_url ? (
							// <img className="image image--big" alt="" src={item.full_img_url.source_url}/>
							<div
								className="image image--big"
								style={{
									backgroundImage: `url(${item.full_img_url.source_url})`,
								}}
							/>
						) : item.text_right ? (
							<div className="case-container">
								<div className="row split-content">
									<div className="col-md-5">
										<img
											className="image image--left img-fluid"
											alt=""
											src={item.img_left_url.source_url}
										/>
									</div>
									<div className="col-md-6 offset-md-1">
										<div
											dangerouslySetInnerHTML={{
												__html: item.text_right,
											}}
										/>
									</div>
								</div>
							</div>
						) : item.text_left ? (
							<div className="case-container">
								<div className="row split-content">
									<div className="col-md-6">
										<div
											dangerouslySetInnerHTML={{
												__html: item.text_left,
											}}
										/>
									</div>
									<div className="col-md-5 offset-md-1">
										<img
											className="image image--right img-fluid"
											alt=""
											src={item.img_right_url.source_url}
										/>
									</div>
								</div>
							</div>
						) : item.other_projects_header ? (
							<div className="other-posts-section">
								<h2 className="other-posts-section__title">
									{item.other_projects_header}
								</h2>
								<div className="row other-posts-row">
									<div className="col-lg-6">
										<Link to={item.left_post.link}>
											<div className="other-post-wrapper other-post-wrapper--left">
												<h3 className="other-post-wrapper__title">
													{item.left_post.title}
												</h3>
												<img
													src={
														item.left_post.image
															.source_url
													}
													alt=""
													className="other-post-wrapper__image img-fluid"
												/>
											</div>
										</Link>
									</div>
									<div className="col-lg-6">
										<Link to={item.right_post.link}>
											<div className="other-post-wrapper other-post-wrapper--right">
												<h3 className="other-post-wrapper__title">
													{item.right_post.title}
												</h3>
												<img
													src={
														item.right_post.image
															.source_url
													}
													alt=""
													className="other-post-wrapper__image img-fluid"
												/>
											</div>
										</Link>
									</div>
								</div>
							</div>
						) : (
							""
						)}
					</div>
				))}
			</section>

			<section className="single-post-section-contact case-study">
				<div className="form-wrapper">
					<h2 className="single-post-section-contact__title mb-40">
						Skontaktuj się z nami! <br />
						Wypełnij <span>formularz</span>
					</h2>
					<ContactFormLight
						locales={locales["pl"]}
						instagramSelect={false}
						thankYouTarget="dziekujemy-za-kontakt-case-study"
					/>
				</div>
				<img
					className="img-fluid single-offer-section-contact__hand"
					src={require("../assets/images/home-contact-hand-right.png")}
					alt=""
				/>
			</section>
		</Layout>
	);
};

export default pageCaseStudy;
